import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Helmet from 'react-helmet';

import colors from '@/assets/colors';
import smallDecor from '@/assets/decor-small.svg';
import MainContent from '@/components/main-content';
import T from '@/components/triangle';
import { encode, media } from '@/utils';
import '@/stylesheets/contact';

const inputStyles = css`
  appearance: none;
  background: white;
  border: none;
  border-radius: .25em;
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 1.25em 1em;
  width: 100%;

  .has-value & {
    + span {
      transform: translateY(-.5em) scale(.65);
    }
  }
`;

const Container = styled(MainContent)`
  margin: 2em auto;

  ${media.phone`
    margin: 5em auto 2em;
  `};
`;

const Title = styled.h1`
  color: ${colors.brand.secondary};
  font-weight: 900;
  line-height: 1.25;
  margin: 0 0 0 .25em;
  text-align: right;
  width: calc(25% - .5em);

  ${media.phone`
    margin: 0 0 .5em;
    text-align: left;
    width: auto;
  `}
`;

const Description = styled.p`
  color: rgba(255, 255, 255, .75);
  margin: 0 0 0 calc(25% + .5em);
  width: calc(50% - 1em);

  ${media.phone`
    margin: 0;
    width: auto;
  `}

  &::after {
    background-image: url(${smallDecor});
    background-size: 100%;
    content: '';
    display: inline-block;
    height: 1em;
    transform: translate(.5em, .25em);
    width: 1em;
  }
`;

const Triangle = styled(T)`
  mix-blend-mode: difference;
  z-index: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 3em auto;
  position: relative;
  width: calc(50% - 1em);
  z-index: 1;

  ${media.phone`
    width: auto;
  `};
`;

const Label = styled.label`
  display: block;
  font-family: sans-serif;
  font-size: .85em;
  position: relative;

  & + & {
    margin-top: 1.5em;
  }

  span {
    left: 1em;
    pointer-events: none;
    position: absolute;
    top: 1em;
    transform-origin: left top;
    transition: transform 250ms;
  }
`;

const Input = styled.input`
  ${inputStyles}
  line-height: 1;
  transition: height 250ms;

  .has-value & {
    height: 3.55em;
    padding-bottom: .5em;
  }
`;

const TextArea = styled.textarea`
  ${inputStyles};
  min-height: 10em;
  resize: none;

  .has-value & {
    padding-top: 1.75em;
  }
`;

const Button = styled.button`
  align-self: flex-end;
  background: ${colors.brand.primary};
  border: none;
  border-radius: .25em;
  cursor: pointer;
  font-family: sans-serif;
  line-height: 1;
  margin-top: 1.5em;
  padding: .75em 1em;
  text-transform: uppercase;
`;

const Contact = () => {
  const [state, setState] = useState({});

  const handleChange = name => event => {
    const { value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', ...state })
      });
      setState({ email: '', message: '' });
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <Container>
      <Helmet bodyAttributes={{ class: 'contact' }} />
      <Title>Get in touch</Title>
      <Description>Thinking about working together? Awesome! Use the contact form below and I'll get back to you as soon as possible.</Description>
      <Triangle color={colors.brand.secondary} size={1000} />
      <Triangle yOffset={'20vh'} color={colors.brand.primary} reverse size={1000} />
      <Form
        name="contact"
        method="post"
        action="/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="contact" />
        <Label className={state.email && 'has-value'}>
          <Input name="email" value={state.email} onChange={handleChange('email')} type="email" />
          <span>E-mail</span>
        </Label>
        <Label className={state.message && 'has-value'}>
          <TextArea name="message" value={state.message} onChange={handleChange('message')} />
          <span>Message</span>
        </Label>
        <Button onClick={handleSubmit}>Send</Button>
      </Form>
    </Container>
  );
};

export default Contact;
